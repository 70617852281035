import { mergeClasses } from '@griffel/react';
import { useTableBodyStyles_unstable } from '../TableBody/useTableBodyStyles.styles';
export const dataGridBodyClassNames = {
    root: 'fui-DataGridBody'
};
/**
 * Apply styling to the DataGridBody slots based on the state
 */ export const useDataGridBodyStyles_unstable = (state)=>{
    useTableBodyStyles_unstable(state);
    state.root.className = mergeClasses(dataGridBodyClassNames.root, state.root.className);
    return state;
};
