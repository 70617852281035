import { mergeClasses } from '@griffel/react';
import { useTableCellStyles_unstable } from '../TableCell/useTableCellStyles.styles';
export const dataGridCellClassNames = {
    root: 'fui-DataGridCell'
};
/**
 * Apply styling to the DataGridCell slots based on the state
 */ export const useDataGridCellStyles_unstable = (state)=>{
    useTableCellStyles_unstable(state);
    state.root.className = mergeClasses(dataGridCellClassNames.root, state.root.className);
    return state;
};
