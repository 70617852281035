import { mergeClasses } from '@griffel/react';
import { useTableHeaderStyles_unstable } from '../TableHeader/useTableHeaderStyles.styles';
export const dataGridHeaderClassNames = {
    root: 'fui-DataGridHeader'
};
/**
 * Apply styling to the DataGridHeader slots based on the state
 */ export const useDataGridHeaderStyles_unstable = (state)=>{
    useTableHeaderStyles_unstable(state);
    state.root.className = mergeClasses(dataGridHeaderClassNames.root, state.root.className);
    return state;
};
