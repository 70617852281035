import { mergeClasses } from '@griffel/react';
import { useTableSelectionCellStyles_unstable } from '../TableSelectionCell/useTableSelectionCellStyles.styles';
export const dataGridSelectionCellClassNames = {
    root: 'fui-DataGridSelectionCell',
    checkboxIndicator: 'fui-DataGridSelectionCell__checkboxIndicator',
    radioIndicator: 'fui-DataGridSelectionCell__radioIndicator'
};
/**
 * Apply styling to the DataGridSelectionCell slots based on the state
 */ export const useDataGridSelectionCellStyles_unstable = (state)=>{
    useTableSelectionCellStyles_unstable(state);
    state.root.className = mergeClasses(dataGridSelectionCellClassNames.root, state.root.className);
    if (state.checkboxIndicator) {
        state.checkboxIndicator.className = mergeClasses(dataGridSelectionCellClassNames.checkboxIndicator, state.checkboxIndicator.className);
    }
    if (state.radioIndicator) {
        state.radioIndicator.className = mergeClasses(dataGridSelectionCellClassNames.radioIndicator, state.radioIndicator.className);
    }
    return state;
};
