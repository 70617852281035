import { mergeClasses } from '@griffel/react';
import { useTableStyles_unstable } from '../Table/useTableStyles.styles';
export const dataGridClassNames = {
    root: 'fui-DataGrid'
};
/**
 * Apply styling to the DataGrid slots based on the state
 */ export const useDataGridStyles_unstable = (state)=>{
    useTableStyles_unstable(state);
    state.root.className = mergeClasses(dataGridClassNames.root, state.root.className);
    return state;
};
